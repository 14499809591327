<template>
  <section id="about" class="section pb-3">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-lg-5 col-md-5"
          :data-aos="sectionConfig.intro.aboutImg.animate.type"
          :data-aos-duration="sectionConfig.intro.aboutImg.animate.duration"
        >
          <div class="about-hero">
            <img
              src="@assets/images/home/about.png"
              class="img-fluid mx-auto d-block about-tween position-relative"
              alt="About Syed Hassan Ali - devhassy - syedhassan06 | Software Engineer | Software Developer | Full Stack Developer"
            />
          </div>
        </div>
        <!--end col-->

        <div
          :data-aos="sectionConfig.intro.aboutContent.animate.type"
          :data-aos-duration="sectionConfig.intro.aboutContent.animate.duration"
          class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0"
        >
          <div class="section-title mb-0 ml-lg-5 ml-md-3">
            <h4 class="title text-primary mb-3">
              {{ portfolioDetails.fullName }}
            </h4>
            <h6 class="designation mb-3">
              {{ portfolioDetails.passionateText }}
              <span class="text-primary">
                {{ portfolioDetails.role }}
              </span>
            </h6>
            <div v-html="portfolioDetails.aboutMeText"></div>
            <img src="@assets/images/sign.png" height="25" alt="" />
            <div class="mt-4">
              <a href="#projects" class="btn btn-primary mouse-down">
                View Portfolio
              </a>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
</template>

<script>
import generalMixin from "@/mixins/general.mixin";

export default {
  inject: ["portfolioDetails"],
  mixins: [generalMixin],
};
</script>

<style></style>
