<template>
  <div
    :data-aos="sectionConfig.contact.animate.type"
    :data-aos-duration="sectionConfig.contact.animate.duration"
  >
    <section id="contact" class="section pb-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <div class="titles">
                <h4 class="title title-line text-uppercase mb-4 pb-4">
                  Get In Touch
                </h4>
                <span></span>
              </div>
              <p class="text-muted mx-auto para-desc mb-0">
                {{ portfolioDetails.contactText }}
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-md-4 mt-4 pt-2">
            <div class="contact-detail text-center">
              <div class="icon text-primary">
                <i data-feather="phone" class="fea icon-md"></i>
              </div>
              <div class="content mt-4">
                <h5 class="title text-uppercase">Phone</h5>
                <span class="text-primary">
                  {{ portfolioDetails.addressInfo.contact }}
                </span>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-4 pt-2">
            <div class="contact-detail text-center">
              <div class="icon text-primary">
                <i data-feather="mail" class="fea icon-md"></i>
              </div>
              <div class="content mt-4">
                <h5 class="title text-uppercase">Email</h5>
                <!-- <vue-typed-js
                  :show-cursor="false"
                  :back-speed="80"
                  :back-delay="1000"
                  :start-delay="500"
                  :loop="true"
                  :type-speed="80"
                  :strings="[
                    'Full Stack Developer',
                    'Frontend Developer',
                    'Backend Developer',
                    'Angular/ Vue/ React',
                    'Laravel/ NodeJS',
                    'Flutter',
                  ]"
                >
                  <h1>
                    Hey
                    <span class="typing"></span>
                  </h1>
                </vue-typed-js> -->
                <span class="text-primary">
                  {{ portfolioDetails.addressInfo.email }}
                </span>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-4 pt-2">
            <div class="contact-detail text-center">
              <div class="icon text-primary">
                <i data-feather="map-pin" class="fea icon-md"></i>
              </div>
              <div class="content mt-4">
                <h5 class="title text-uppercase">Location</h5>
                <span class="text-primary">
                  {{ portfolioDetails.addressInfo.location }}
                </span>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <section class="section pt-2 mt-2">
      <div v-if="false" class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="custom-form mb-sm-30">
              <div id="message"></div>
              <form
                id="contact-form"
                method="post"
                action="php/contact.php"
                name="contact-form"
              >
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            class="form-control border rounded"
                            placeholder="First Name :"
                          />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            class="form-control border rounded"
                            placeholder="Your email :"
                          />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-12">
                        <div class="form-group">
                          <input
                            id="subject"
                            name="subject"
                            class="form-control border rounded"
                            placeholder="Your subject :"
                          />
                        </div>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </div>
                  <!--end col-->

                  <div class="col-lg-6">
                    <div class="form-group">
                      <textarea
                        id="comments"
                        name="comments"
                        rows="4"
                        class="form-control border rounded"
                        placeholder="Your Message :"
                      ></textarea>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
                <div class="row">
                  <div class="col-sm-12 text-right">
                    <input
                      id="submit"
                      type="submit"
                      name="send"
                      class="submitBnt btn btn-primary"
                      value="Send Message"
                    />
                    <div id="simple-msg"></div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
              <!--end form-->
            </div>
            <!--end custom-form-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
  </div>
</template>

<script>
import generalMixin from "@/mixins/general.mixin";

export default {
  inject: ["portfolioDetails"],
  mixins: [generalMixin],
};
</script>

<style></style>
