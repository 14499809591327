<template>
  <section
    class="cta-full border-top"
    :data-aos="sectionConfig.skill.animate.type"
    :data-aos-duration="sectionConfig.skill.animate.duration"
    :data-aos-easing="sectionConfig.skill.animate.easing"
  >
    <div class="container-fluid">
      <div class="row position-relative">
        <div
          class="col-lg-4 padding-less img"
          :style="{ 'background-image': cssProps.backgroundImage }"
          data-jarallax='{"speed": 0.5}'
        ></div>
        <!-- end col -->
        <div class="col-lg-8 offset-lg-4">
          <div class="cta-full-img-box">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <div class="section-title">
                  <div class="titles">
                    <h4 class="title title-line text-uppercase mb-4 pb-4">
                      Work Expertise
                    </h4>
                    <span></span>
                  </div>
                  <h5>{{ portfolioDetails.skillShortText }}</h5>
                  <p class="text-muted mx-auto para-desc mb-0">
                    {{ portfolioDetails.skillText }}
                  </p>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->

            <div class="row progress-container">
              <div class="col-lg-3 col-md-4 col-12">
                <ul
                  id="pills-tab"
                  class="nav nav-pills flex-column px-0"
                  role="tablist"
                >
                  <li
                    v-for="(skill, index) in portfolioDetails.techSkills"
                    :key="'skill-' + index"
                    class="nav-item mt-4 pt-2"
                  >
                    <a
                      :id="skill.id + '-tab'"
                      class="nav-link rounded"
                      :class="{
                        active: selectedIndex == index,
                      }"
                      data-toggle="pill"
                      :href="'#' + skill.id"
                      role="tab"
                      :aria-controls="skill.id"
                      aria-selected="false"
                      @click="selectTab(index)"
                    >
                      <div class="skill-container text-center pt-1 pb-1">
                        <h6 class="title mb-0">
                          {{ skill.title }}
                        </h6>
                      </div>
                    </a>
                    <!--end nav link-->
                  </li>
                  <!--end nav item-->
                </ul>
                <!--end nav pills-->
              </div>
              <!--end col-->

              <div class="col-lg-9 col-md-8 col-12">
                <div id="pills-tabContent" class="tab-content pl-lg-4">
                  <div
                    v-for="(skill, index) in portfolioDetails.techSkills"
                    :id="skill.id"
                    :key="'skill-content-' + index"
                    class="tab-pane fade show"
                    :class="{ active: selectedIndex == index }"
                    role="tabpanel"
                    :aria-labelledby="skill.id + '-tab'"
                  >
                    <div class="row">
                      <div
                        v-for="(area, areaIndex) in skill.areas"
                        :key="'area-' + areaIndex"
                        class="col-md-6 col-sm-12 col-xs-12"
                        :class="area.class"
                      >
                        <div class="progress-box mt-4 pt-2">
                          <h6 class="progress-box__title">
                            {{ area.name }}
                          </h6>
                          <div class="progress">
                            <div
                              class="progress-bar position-relative bg-primary"
                              :style="{ width: area.progress + '%' }"
                            >
                              <div
                                class="progress-value d-block text-dark h6"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end process box-->
                  </div>
                  <!--end teb pane-->
                </div>
                <!--end tab content-->
              </div>
              <!--end col-->
            </div>
            <!-- end row -->
          </div>
          <!-- end about detail -->
        </div>
        <!-- end col -->
      </div>
      <!--end row-->
    </div>
    <!--end container fluid-->
  </section>
</template>

<script>
import generalMixin from "@/mixins/general.mixin";

export default {
  inject: ["portfolioDetails"],
  mixins: [generalMixin],
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require("@/assets/images/laptop_mobile_img.jpg")})`,
      },
      selectedIndex: 0,
    };
  },
  methods: {
    selectTab(index) {
      this.selectedIndex = index;
    },
  },
};
</script>
