import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

import GlobalPlugin from "./plugins/global.plugin";

// plugin setup
Vue.use(GlobalPlugin);

new Vue({
  render: (h) => h(App),
}).$mount("#app");
