import "../assets/css/main.css";
import Vue from "vue";

import "jquery.easing";
import "bootstrap";
import "@assets/script/scrollspy";
// import { ModalPlugin } from "bootstrap-vue";

import { VueTypedJs } from "vue-typed-js";

export default {
  install(_Vue) {
    Vue.use(VueTypedJs);

    Vue.component("vue-typed-js", VueTypedJs);
    /* Vue.use(GlobalComponents);
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin); */
  },
};
