<template>
  <section
    id="resume"
    :data-aos="sectionConfig.workExpr.animate.type"
    :data-aos-duration="sectionConfig.workExpr.animate.duration"
    class="section resume-sec"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title">
            <div class="titles">
              <h4 class="title title-line text-uppercase mb-4 pb-4">
                Work Participation
              </h4>
              <span></span>
            </div>
            <p class="text-muted mx-auto para-desc mb-0">
              {{ portfolioDetails.workParticipationText }}
            </p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row">
        <div class="col-12">
          <div class="main-icon rounded-pill text-center mt-4 pt-2">
            <i data-feather="star" class="fea icon-md-sm"></i>
          </div>
          <div class="timeline-page pt-2 position-relative">
            <div
              v-for="(experience, index) in portfolioDetails.workExperience"
              :key="'expr-' + index"
              class="timeline-item mt-4"
            >
              <div class="row">
                <template>
                  <div v-if="index % 2 == 0" class="col-lg-6 col-md-6 col-sm-6">
                    <div
                      class="duration date-label-left border rounded p-2 pl-4 pr-4 position-relative shadow text-left"
                    >
                      {{ experience.duration }}
                    </div>
                  </div>
                  <div
                    v-if="index % 2 != 0"
                    class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2"
                  >
                    <div
                      class="event event-description-left rounded p-4 border float-left text-right"
                    >
                      <h5 class="title mb-0 text-capitalize">
                        {{ experience.company }}
                      </h5>
                      <small class="company font-sans font-weight-600">
                        {{ experience.designation }} - {{ experience.techRole }}
                      </small>
                      <div
                        class="timeline-subtitle mt-3 mb-0 text-muted"
                        v-html="experience.desc"
                      ></div>
                    </div>
                  </div>
                </template>

                <template>
                  <div v-if="index % 2 == 0" class="col-lg-6 col-md-6 col-sm-6">
                    <div
                      class="event event-description-right rounded p-4 border float-left text-left"
                    >
                      <h5 class="title mb-0 text-capitalize">
                        {{ experience.company }}
                      </h5>
                      <small class="company font-sans font-weight-600">
                        {{ experience.designation }} - {{ experience.techRole }}
                      </small>
                      <div
                        class="timeline-subtitle mt-3 mb-0 text-muted"
                        v-html="experience.desc"
                      ></div>
                    </div>
                  </div>
                  <div
                    v-if="index % 2 != 0"
                    class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1"
                  >
                    <div
                      class="duration duration-right rounded border p-2 pl-4 pr-4 position-relative shadow text-left"
                    >
                      {{ experience.duration }}
                    </div>
                  </div>
                </template>

                <!--end col-->

                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end timeline item-->
          </div>
          <!--end timeline page-->
          <!-- TIMELINE END -->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
</template>

<script>
import generalMixin from "@/mixins/general.mixin";

export default {
  inject: ["portfolioDetails"],
  mixins: [generalMixin],
};
</script>

<style></style>
