<template>
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-custom navbar-light sticky"
  >
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="@assets/images/dev_hassy_logo.png" height="20" alt="" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span data-feather="menu" class="fea icon-md"></span>
      </button>
      <!--end button-->

      <div id="navbarCollapse" class="collapse navbar-collapse">
        <ul class="navbar-nav navbar-nav-link mx-auto">
          <li class="nav-item active">
            <a class="nav-link" href="#home">Home</a>
          </li>
          <!--end nav item-->
          <li class="nav-item">
            <a class="nav-link" href="#services">Services</a>
          </li>
          <!--end nav item-->
          <li class="nav-item">
            <a class="nav-link" href="#resume">Resume</a>
          </li>
          <!--end nav item-->
          <li class="nav-item">
            <a class="nav-link" href="#projects">Projects</a>
          </li>
          <!--end nav item-->
          <li class="nav-item">
            <a class="nav-link" href="#contact">Contact</a>
          </li>
          <!--end nav item-->
        </ul>

        <ul
          class="list-unstyled mb-0 mt-2 mt-sm-0 social-icon light-social-icon"
        >
          <li class="list-inline-item">
            <a :href="portfolioDetails.socialLinks.linkedin" target="__blank">
              <i class="mdi mdi-linkedin"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a :href="portfolioDetails.socialLinks.github" target="__blank">
              <i class="mdi mdi-github-circle"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a :href="portfolioDetails.socialLinks.facebook" target="__blank">
              <i class="mdi mdi-facebook"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!--end container-->
  </nav>
</template>

<script>
export default {
  inject: ["portfolioDetails"],
};
</script>

<style></style>
