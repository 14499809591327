<template>
  <section id="projects" class="section bg-light">
    <CoolLightBox
      :items="selectedProjectImages"
      :index="canShowGallery"
      :effect="'fade'"
      @close="closeGallery()"
    ></CoolLightBox>
    <div class="container">
      <div
        :data-aos="sectionConfig.portfolio.animate.type"
        :data-aos-duration="sectionConfig.portfolio.animate.duration"
        class="row justify-content-center"
      >
        <div class="col-12 text-center">
          <div class="section-title">
            <div class="titles">
              <h4 class="title title-line text-uppercase mb-4 pb-4">
                My Portfolio
              </h4>
              <span></span>
            </div>
            <h5>
              {{ portfolioDetails.portfolioText }}
            </h5>
            <!-- <p class="text-muted mx-auto para-desc mb-0">
              {{ portfolioDetails.portfolioText }}
            </p> -->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>

    <!--start portfolio section-->
    <div
      class="container"
      :data-aos="sectionConfig.portfolio.animate.type"
      :data-aos-duration="sectionConfig.portfolio.animate.duration"
    >
      <div class="row mt-4 pt-2">
        <div class="col-lg-12">
          <ul class="portfolioFilter text-center mb-0 list-unstyled">
            <li class="list-inline-item mb-3">
              <a
                href="javascript:void(0)"
                class="text-dark mr-2 py-2 px-3 rounded project-item-thumb"
                :class="{ active: !selectedProjectCategory }"
                @click="onFilterProject()"
              >
                All
              </a>
            </li>
            <li
              v-for="projectCategory in projectCategories"
              :key="'category_' + projectCategory.slug"
              class="list-inline-item mb-3"
            >
              <a
                href="javascript:void(0)"
                class="text-dark mr-2 py-2 px-3 rounded project-item-thumb"
                :class="{
                  active:
                    selectedProjectCategory &&
                    selectedProjectCategory.slug == projectCategory.slug,
                }"
                @click="onFilterProject(projectCategory)"
              >
                {{ projectCategory.title }}
              </a>
            </li>
          </ul>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <!-- End row isotope -->
      <div class="portfolioContainer">
        <isotope
          ref="isotopeRef"
          item-selector="project-item"
          :options="option"
          :list="projectList"
          @filter="filterOption = arguments[0]"
          @sort="sortOption = arguments[0]"
        >
          <div
            v-for="element in projectList"
            :key="element.name"
            class="col-lg-4 col-md-6 mt-4 pt-2 natural personal"
          >
            <div
              class="portfolio-box rounded shadow position-relative overflow-hidden"
            >
              <div class="portfolio-box-img position-relative overflow-hidden">
                <img
                  :src="projectImg(element.featureImg)"
                  class="img-fluid"
                  alt="member-image"
                />
                <div class="overlay-work">
                  <div class="work-content text-center">
                    <a
                      href="javascript:void(0)"
                      class="text-light work-icon bg-dark d-inline-block rounded-pill mfp-image"
                      @click="onSelectProject(element)"
                    >
                      <i
                        data-feather="camera"
                        class="fea icon-sm image-icon"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="gallary-title py-4 text-center">
                <h5>
                  <a href="page-portfolio-detail.html" class="title text-dark">
                    {{ element.title }}
                  </a>
                </h5>
                <span>{{ element.category[0].title }}</span>
              </div>
            </div>
          </div>
          <!-- <div v-for="element in projectList" :key="element.id">
              {{ element.title }}
            </div> -->
        </isotope>
      </div>

      <!-- <div class="row">
        <div class="col-lg-12 mt-4 pt-2">
          <div class="text-center">
            <a href="page-portfolio.html" class="btn btn-outline-primary">
              More works
              <i data-feather="refresh-cw" class="fea icon-sm"></i>
            </a>
          </div>
        </div>
      </div> -->
      <!--end row-->
    </div>
    <!--end portfolio section-->
  </section>
</template>

<script>
import isotope from "vueisotope";
import CoolLightBox from "vue-cool-lightbox";

import generalMixin from "@/mixins/general.mixin";

// import { Helper } from "@/utils/helper";

export default {
  inject: ["portfolioDetails"],
  components: {
    isotope,
    CoolLightBox,
  },
  mixins: [generalMixin],
  data() {
    return {
      selectedProject: null,
      selectedProjectCategory: null,
      projectList: this.portfolioDetails.portfolio.projects.map((project) => {
        project.category = project.category.map((projectCategorySlug) => {
          return this.portfolioDetails.portfolio.category.find(
            (categoryItem) => categoryItem.slug == projectCategorySlug
          );
        });
        return project;
      }),
      selectedProjectImgIndex: null,
      /* projectList: [
        {
          id: 1,
          title: "The Usefulness",
          category: "Photography",
          categorySlug: "photography",
        },
        {
          id: 2,
          title: "The Nonsensical content",
          category: "Illustrations",
          categorySlug: "illustration",
        },
        {
          id: 3,
          title: "Prevents Patterns",
          category: "Corrporate",
          categorySlug: "devlopment",
        },
        {
          id: 4,
          title: "The Usefulness",
          category: "Photography",
          categorySlug: "photography",
        },
        {
          id: 5,
          title: "The Usefulness",
          category: "Illustrations",
          categorySlug: "illustration",
        },
        {
          id: 6,
          title: "Ius Dissentiunt",
          category: "Devlopment",
          categorySlug: "devlopment",
        },
      ], */
      option: {
        layoutMode: "fitRows",
        itemSelector: ".project-item",
        containerStyle: {
          display: "flex",
          "flex-wrap": "wrap",
          "margin-right": "-15px",
          "margin-left": "-15px",
          position: "relative",
        },
        getFilterData: {
          filterBySlug: (project) => {
            if (!this.selectedProjectCategory) {
              return true;
            }
            return (
              project.category
                .map((projectCategory) => projectCategory.slug)
                .indexOf(this.selectedProjectCategory.slug) !== -1
            );
            /* this.selectedProjectCategory;
            return this.portfolioDetails.portfolio.projects.filter(
              (projectItem) => {
                return projectItem.category
                  .map((projectCategory) => projectCategory.slug)
                  .filter(
                    (projectCategorySlug) =>
                      projectCategorySlug == this.selectedProjectCategory.slug
                  ).length;
              }
            ); */
          },
          //   filterBySlug: this.filterBySlugCallback,
        },
      },
      $container: null,
      version: null,
    };
  },
  computed: {
    projectCategories() {
      return this.portfolioDetails.portfolio.category;
    },
    projects() {
      return this.portfolioDetails.portfolio.projects.map((project) => {
        project.category = project.category.map((projectCategorySlug) => {
          return (
            projectCategorySlug ===
            this.portfolioDetails.portfolio.category.find(
              (categoryItem) => categoryItem
            )
          );
        });
        return project;
      });
    },
    projectImg() {
      return (img) => {
        return require("@assets/images/portfolio/" + img);
      };
    },
    canShowGallery() {
      return this.selectedProject ? 0 : null;
    },
    selectedProjectImages() {
      return this.selectedProject ? this.selectedProject.images : [];
    },
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.version = urlParams.get("v");

    setTimeout(() => {
      if (this.selectedProjectCategory) {
        this.onFilterProject(this.selectedProjectCategory);
      } else {
        this.onFilterProject();
      }
    }, 1500);
  },
  methods: {
    onFilterProject(projectCategory) {
      this.selectedProjectCategory = projectCategory || null;
      this.$refs.isotopeRef.filter("filterBySlug");
    },
    onSelectProject(project) {
      this.selectedProject = { ...project, images: [...project.images] };
      this.selectedProject.images = this.selectedProject.images
        .filter((item) => {
          if (this.version == 2) {
            return true;
          }
          return item.hidden !== true;
        })
        .map((imgItem) => {
          return require("@assets/images/portfolio/" + imgItem.path);
        });
      this.selectedProjectImgIndex = 0;
    },
    closeGallery() {
      this.selectedProject = null;
      this.selectedProjectImgIndex = null;
    },
  },
};
</script>

<style scoped>
#projects {
  padding-bottom: 60px;
}
</style>
