export const PORTFOLIO_DATA = {
  lookingText: "Looking for a ",
  passionateText: " I'm a Passionate",
  fullName: "Syed Hassan Ali",
  fullNameHeading: "I'm Syed Hassan Ali",
  role: "Full Stack Developer",
  roleList: [
    "Software Developer!",
    "Full Stack Developer!",
    "Frontend Developer!",
    // "Backend Developer!",
    "Angular/ Vue/ React Developer",
    "PHP/ Laravel/ NodeJS Developer",
    "Flutter  Developer",
  ],
  addressInfo: {
    email: "syedhassanali06@gmail.com",
    contact: "+92 334-2620618",
    location: "Karachi, Pakistan",
  },
  socialLinks: {
    github: "https://github.com/syedhassan06",
    linkedin: "https://www.linkedin.com/in/syedhassan06/",
    facebook: "https://www.facebook.com/syedhassan06/",
    messanger: "http://m.me/syedhassan06",
    twitter: "https://twitter.com/syedhassan06",
    instagram: "https://www.instagram.com/devhassy",
    skype: "skype:syedhassan.06?chat",
  },
  resumeLink:
    "https://drive.google.com/file/d/13w4jVVWDLHCWnsapK1bjFZQT-_8XzhIc/view?usp=sharing",
  services: [
    {
      title: "Frontend Development",
      icon: "airplay",
      extra:
        "Build a scalable, reliable, and secure front end to ensure a dynamic data flow and flawless operation of all customer-facing elements.",
      desc: "Design a truly innovative, intuitive, and beautiful front end that speaks to your customers and helps them engage with your website or application.",
    },
    {
      title: "Full-Stack Development",
      icon: "codepen",
      desc: "From a growing business to an established one, Using full-stack software development services to ensure scalability and responsiveness at every stage of the development cycle.",
    },
    {
      title: "Mobile Apps",
      icon: "smartphone",
      desc: "Provide maximum value for customers with high-quality Hybrid applications that help improve your business processes, and compete with the industry's finest.",
    },
    {
      title: "Saas Platforms",
      icon: "layers",
      desc: "Crafts a plan to modernize your legacy software system or develop new software products using my skills & expertise. Understand the scope, explore the potential and reduce the development stress, with efficient and performing applications.",
    },
    {
      title: "MVP Development",
      icon: "send",
      desc: "Assist small businesses, and product companies in finding a market-fit, developing an MVP for startups, and achieving break-even point. Offered service that can help implement your project of any complexity.",
    },
    {
      title: "App Maintance",
      icon: "settings",
      desc: "Ensured extensive and risk-free code audit and system review services for clients to improve their software products, add new features, or make any changes in the existing code base.",
    },
  ],
  serviceShortText: "Turn Your Idea Into Groundbreaking Product",
  serviceText:
    "Deliver end-to-end software products, from prototyping to design, development and post-launch support and maintenance.",

  workExperience: [
    {
      company: "Optimyse",
      duration: "Nov 2019 - Present",
      designation: "Full Stack Developer",
      techRole: "Angular | Node | Flutter",
      desc: "<p>Responsible for designing and developing full-stack applications including their front-end and back-end development according to the business objectives and needs of the various clients. Work in an agile software development team to complete various epics through sprints, working in conjunction with other developers and engineers from external companies.</p>",
    },
    {
      company: "Wiztech",
      duration: "April 2016 - Nov 2019",
      designation: "Full Stack Developer",
      techRole: "Angular | Vue | Laravel",
      desc: "<p>Designed & architected single page applications using Angular, Typescript, Vue and Webpack. Analyze application usability and performance, recommending changes to improve functionality. Designed more scalable and reliable solutions that allowed 100% deadline compliance.</p>",
    },
    {
      company: "Value Technologies",
      duration: "Aug 2015 - April 2016",
      designation: "PHP Developer",
      techRole: "LAMP | Laravel | Wordpress",
      desc: "<p>Developed an in-house CRM using LAMP & Laravel to allow for easier personalization for company use. Performed technical analysis to select efficient adjustments based on company specifics. Developed app integration with REST / SOAP and other APIs for Google Maps, payment processors, and other services.</p>",
    },
  ],

  workParticipationText: `Utilizing a wide variety of programs and tools to provide high-quality and cost-effective applications/systems to boost organizational efficiency and productivity.`,
  skillShortText: "Great Products Require Great Technologies",
  skillShortTextExtra: "Great Skill, Better Results",
  skillText:
    "I love to learn new things and experiment with new technologies. These are some of the major languages, technologies, tools and platforms I have worked with.",

  techSkills: [
    {
      id: "frontend",
      title: "Front-End",
      areas: [
        { name: "HTML5", progress: 90 },
        { name: "CSS3", progress: 90 },
        { name: "Javascript (ES6)", progress: 85 },
        { name: "Bootstrap", progress: 85 },
        { name: "Material Design", progress: 80 },
        { name: "SCSS", progress: 80 },
        { name: "Angular 2+", progress: 88 },
        { name: "Typescript", progress: 86 },
        { name: "Vue JS", progress: 88 },
        { name: "React JS", progress: 80 },
        { name: "Next JS", progress: 70 },
        { name: "JQuery", progress: 88 },
        { name: "Rx JS", progress: 88 },
        { name: "Webpack", progress: 85 },
        { name: "Babel", progress: 85 },
      ],
    },
    {
      id: "backend",
      title: "Back-End",
      areas: [
        { name: "Laravel", progress: 80 },
        { name: "NodeJS", progress: 80 },
        { name: "Express JS", progress: 80 },
        { name: "Nest JS", progress: 70 },
        { name: "REST API's", progress: 80 },
        { name: "GraphQL", progress: 75 },
        { name: "Codeigniter", progress: 80 },
        { name: "Wordpress", progress: 70 },
        { name: "MySQL", progress: 80 },
        { name: "ORM", progress: 80 },
        { name: "MongoDB", progress: 70 },
        { name: "Mongoose", progress: 70 },
        { name: "Firestore", progress: 70 },
      ],
    },
    {
      id: "mobile",
      title: "Mobile Stack",
      areas: [
        { name: "Flutter", progress: 70, class: "col-md-12" },
        { name: "React Native", progress: 50, class: "col-md-12" },
      ],
    },
    {
      id: "tools",
      title: "Development & Operations",
      areas: [
        { name: "Agile", progress: 80, class: "col-md-12" },
        { name: "Scrum", progress: 80, class: "col-md-12" },
        { name: "GIT", progress: 85, class: "col-md-12" },
        { name: "Docker", progress: 79, class: "col-md-12" },
        { name: "Firebase", progress: 80, class: "col-md-12" },
        { name: "Heroku", progress: 80, class: "col-md-12" },
      ],
      test: ["scrum", "agile", "git", "verion control", "docker", "JUnit"],
    },
  ],

  section: {
    home: {
      animate: {
        type: "fade-up",
        duration: null,
      },
    },
    intro: {
      aboutImg: {
        animate: {
          type: "fade-right",
          duration: 1500,
        },
      },
      aboutContent: {
        animate: {
          type: "fade-right",
          duration: 1500,
        },
      },
    },
    skill: {
      animate: {
        type: "fade-right",
        duration: 1300,
        easing: "ease-in-sine",
      },
    },
    service: {
      animate: {
        type: "zoom-in",
        duration: 1200,
      },
    },
    portfolio: {
      animate: {
        type: "fade-right",
        duration: null,
      },
    },
    workExpr: {
      animate: {
        type: "fade-up",
        duration: 1200,
      },
    },
    contact: {
      animate: {
        type: "fade-up",
      },
    },
  },
  portfolio: {
    category: [
      { slug: "hrm", title: "HRM" },
      { slug: "healthcare", title: "Healthcare" },
      { slug: "saas", title: "SaaS" },
      { slug: "ecommerce", title: "E-commerce" },
      { slug: "edtech", title: "Ed-Tech" },
      { slug: "mobility", title: "Mobility" },
      { slug: "custom", title: "Custom" },
      { slug: "e-invoice", title: "E-Invoice" },
    ],
    projects: [
      {
        name: "HRM",
        title: "Human Resource",
        category: ["hrm"],
        featureImg: "hrm/image1.jpg",
        images: [
          { path: "hrm/00.png" },
          { path: "hrm/01.png" },
          { path: "hrm/02.png" },
          { path: "hrm/03.png" },
          { path: "hrm/05.png" },
          { path: "hrm/09.png" },
          { path: "hrm/10.png" },
          { path: "hrm/11.png" },
          { path: "hrm/12.png" },
          { path: "hrm/13.png" },
        ],
      },
      {
        name: "MMW",
        title: "My Mouth Works",
        category: ["healthcare"],
        featureImg: "mmw/image1.jpg",
        images: [
          { path: "mmw/01.png" },
          { path: "mmw/01-1.png" },
          { path: "mmw/01-2.png" },
          { path: "mmw/02.png" },
          { path: "mmw/03.png" },
          { path: "mmw/04.png" },
        ],
      },
      {
        name: "GSD",
        title: "GlobalShop Direct",
        category: ["ecommerce"],
        featureImg: "gsd/image1.jpg",
        images: [
          { path: "gsd/01.png" },
          { path: "gsd/02.png" },
          { path: "gsd/03.jpg" },
          { path: "gsd/04.png" },
          { path: "gsd/06.png" },
          { path: "gsd/07.png" },
        ],
      },
      {
        name: "Healthcare Survey",
        title: "Healthcare Survey",
        category: ["healthcare", "saas"],
        featureImg: "survey/image1.jpeg",
        images: [
          { path: "survey/00.png" },
          { path: "survey/01.png" },
          { path: "survey/03.png" },
          { path: "survey/04.png" },
          { path: "survey/05.png" },
          { path: "survey/06.png" },
        ],
      },
      {
        name: "Luomantarina",
        title: "Online Retail",
        category: ["ecommerce", "saas"],
        featureImg: "lmt/image1.jpg",
        images: [
          { path: "lmt/01.png", hidden: true },
          { path: "lmt/02-1.png", hidden: true },
          { path: "lmt/03.png" },
          { path: "lmt/06.png" },
          { path: "lmt/08.jpg" },
        ],
      },

      {
        name: "Flygi",
        title: "Booking App",
        category: ["mobility"],
        featureImg: "flygi/image1.jpg",
        images: [
          { path: "flygi/0.png" },
          { path: "flygi/02.png" },
          { path: "flygi/01.png" },
          { path: "flygi/05.png" },
          { path: "flygi/07.png" },
        ],
      },
      {
        name: "Whiskers Away",
        title: "Whiskers Away",
        category: ["mobility"],
        featureImg: "whiskers/image1.jpeg",
        images: [
          { path: "whiskers/01.png" },
          { path: "whiskers/02.png" },
          { path: "whiskers/02-1.png" },
          { path: "whiskers/03.png" },
          { path: "whiskers/04.png" },
          { path: "whiskers/05.png" },
          { path: "whiskers/07.png" },
        ],
      },
      {
        name: "BRB",
        title: "BRB Group",
        category: ["mobility"],
        featureImg: "brb/image1.jpg",
        images: [
          { path: "brb/02.jpg" },
          { path: "brb/04.png" },
          { path: "brb/05.png" },
          { path: "brb/06.png" },
          { path: "brb/07.png" },
        ],
      },
      {
        name: "Snickers",
        title: "Real-Time Monitoring",
        category: ["custom"],
        featureImg: "snickers/image1.jpg",
        images: [
          { path: "snickers/01.jpg", hidden: true },
          { path: "snickers/03.png", hidden: true },
          { path: "snickers/05.png", hidden: true },
          { path: "snickers/image1.jpg" },
        ],
      },
      {
        name: "LMS",
        title: "Learning Management",
        category: ["edtech"],
        featureImg: "armd/main.png",
        images: [
          { path: "armd/0.png" },
          { path: "armd/02.png" },
          { path: "armd/03.png" },
        ],
      },
      {
        name: "MHPS",
        title: "MHPS",
        category: ["e-invoice"],
        featureImg: "mhps/image1.jpg",
        images: [
          { path: "mhps/02.png" },
          { path: "mhps/03.png" },
          { path: "mhps/04.png" },
          { path: "mhps/05.png" },
          { path: "mhps/06.png" },
          { path: "mhps/07.png" },
          { path: "mhps/08.png" },
        ],
      },
    ],
  },
  portfolioText:
    "Build state of the art apps that delight both clients and users",
  portfolioTextAlt:
    "Magic happens when we think together and align ourselves for a common goal",
  introText: `
  Enthusiastic and competent Full Stack Developer with 5+ years of experience facilitating cutting-edge engineering solutions with a wide range of different platforms and technology skills. 
  I have experience in Angular, Vue, React, Node, Laravel, Flutter including some other frameworks.`,
  aboutMeText: `
  <p class='text-muted'>A passionate individual who always thrive to work on end to end products which develop sustainable and scalable social and technical systems to create impact. I'm happiest when I'm creating, learning, exploring and thinking about how to make things better. In consequence, I like to dive into new technologies as soon as possible.</p> 
  <p class='text-muted'>I believe I have the capability to overcome difficulties within a short span of time, and I like to keep learning and relearning from opportunities which are present at hand which in return directly benefits my knowledge and since there is no end to receiving that, I keep going with the flow.</p>`,
  contactText: `
  I would love to hear from you. I like to create things with fun, open minded people. 
  I am available to respond faster, for any query and help in application & product development. Feel free to  say Hello! My inbox is open for all.`,
  footerEndingNote:
    "<div>Talk is cheap. Show me the code...</div> I like to design and code beautifully simple things, and I love what I do.",
};
