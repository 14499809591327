<template>
  <section
    id="services"
    class="section bg-light"
    :data-aos="sectionConfig.service.animate.type"
    :data-aos-duration="sectionConfig.service.animate.duration"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title">
            <div class="titles">
              <h4 class="title title-line text-uppercase mb-4 pb-4">
                What Do I Offer ?
              </h4>
              <span></span>
            </div>
            <h5>{{ portfolioDetails.serviceShortText }}</h5>
            <p class="text-muted mx-auto para-desc mb-0">
              {{ portfolioDetails.serviceText }}
            </p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row">
        <div
          v-for="(service, index) in portfolioDetails.services"
          :key="'service-' + index"
          class="col-lg-4 col-md-6 col-12 mt-4 pt-2 service-item-col"
        >
          <div
            class="service-wrapper rounded position-relative text-center border border-footer p-4 pt-5 pb-5"
          >
            <div class="icon text-primary">
              <i :data-feather="service.icon" class="fea icon-md"></i>
            </div>
            <div class="content mt-4">
              <h5 class="title">{{ service.title }}</h5>
              <p class="text-muted mt-3 mb-0">
                {{ service.desc }}
              </p>
            </div>
            <div class="big-icon">
              <i :data-feather="service.icon" class="fea icons"></i>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
</template>

<script>
import generalMixin from "@/mixins/general.mixin";

export default {
  inject: ["portfolioDetails"],
  mixins: [generalMixin],
};
</script>

<style></style>
