<template>
  <div>
    <footer class="footer bg-dark">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <a href="#">
              <img
                src="@assets/images/dev_hassy_logo_white.png"
                height="25"
                alt=""
              />
            </a>
            <p
              class="para-desc mx-auto mt-5"
              v-html="portfolioDetails.footerEndingNote"
            ></p>
            <ul class="list-unstyled mb-0 mt-4 social-icon">
              <li class="list-inline-item">
                <a :href="portfolioDetails.socialLinks.github" target="__blank">
                  <i class="mdi mdi-github-circle"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  :href="portfolioDetails.socialLinks.linkedin"
                  target="__blank"
                >
                  <i class="mdi mdi-linkedin"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a :href="portfolioDetails.socialLinks.skype">
                  <i class="mdi mdi-skype"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  :href="portfolioDetails.socialLinks.facebook"
                  target="__blank"
                >
                  <i class="mdi mdi-facebook"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  :href="portfolioDetails.socialLinks.messanger"
                  target="__blank"
                >
                  <i class="mdi mdi-facebook-messenger"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  :href="portfolioDetails.socialLinks.twitter"
                  target="__blank"
                >
                  <i class="mdi mdi-twitter"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  :href="portfolioDetails.socialLinks.instagram"
                  target="__blank"
                >
                  <i class="mdi mdi-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
    <footer class="footer footer-bar bg-dark">
      <div class="container text-foot text-center">
        <p class="mb-0">
          <span class="text-primary">DevHassy</span>
          © {{ currentYear }}. All Rights Reserved
        </p>
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
  </div>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  inject: ["portfolioDetails"],
};
</script>

<style></style>
