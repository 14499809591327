<template>
  <div id="app">
    <!-- Loader -->
    <div id="preloader">
      <div id="status">
        <div class="logo">
          <img
            src="@assets/images/dev_hassy_logo.png"
            alt="Syed Hassan Ali - devhassy - syedhassan06 | Software Engineer | Software Developer | Full Stack Developer"
            class="d-block mx-auto"
            height="34"
          />
        </div>
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>
    <!-- Loader -->

    <!-- Navbar Start -->
    <navbar></navbar>
    <!--end navbar-->
    <!-- Navbar End -->

    <!-- HOME START-->
    <section id="home" class="bg-modern bg-light bg-animation-right">
      <div class="container">
        <div
          class="row align-items-center position-relative"
          style="z-index: 1"
        >
          <div class="col-lg-7 col-md-7">
            <div class="title-heading mt-5">
              <vue-typed-js
                :show-cursor="true"
                :back-speed="40"
                :back-delay="2500"
                :start-delay="500"
                :loop="true"
                :type-speed="40"
                :strings="portfolioDetails.roleList"
              >
                <h6 class="sub-title">
                  {{ portfolioDetails.lookingText }}
                  <span class="typing"></span>
                </h6>
              </vue-typed-js>
              <!-- <h6 class="sub-title">{{ portfolioDetails.lookingText }}</h6> -->
              <h1 class="heading text-primary mb-3">
                {{ portfolioDetails.fullNameHeading }}
              </h1>
              <p class="para-desc text-muted">
                {{ portfolioDetails.introText }}
              </p>
              <div class="mt-4 pt-2">
                <a
                  href="#contact"
                  class="btn btn-primary mouse-down rounded mb-2 mr-2"
                >
                  Hire me
                </a>
                <a
                  :href="portfolioDetails.resumeLink"
                  target="_blank"
                  class="btn btn-outline-primary rounded mb-2"
                >
                  Download CV
                  <i data-feather="download" class="fea icon-sm"></i>
                </a>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="hero-img">
              <img
                src="@assets/images/home/intro_dark.png"
                class="img-fluid"
                alt="Syed Hassan Ali - devhassy - syedhassan06 | Software Engineer | Software Developer |Full Stack Developer"
              />
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <a
        href="#about"
        data-scroll-nav="1"
        class="mouse-icon d-none d-md-inline-block rounded-pill bg-transparent mouse-down"
      >
        <span class="wheel position-relative d-block mover"></span>
      </a>
    </section>
    <!--end section-->
    <!-- HOME END-->

    <!-- About Start -->
    <intro></intro>
    <!-- About end -->

    <!-- Services Start -->
    <app-service></app-service>
    <!-- Services End -->

    <!-- Resume Start -->
    <work-experience></work-experience>
    <!--end section-->
    <!-- Skill End -->

    <!-- Skill & CTA START -->
    <skill></skill>
    <!--end section-->
    <!-- Skill & CTA End -->

    <!-- Projects End -->
    <portfolio ref="portfolioSec"></portfolio>
    <!--end section-->
    <!-- Projects End -->

    <!-- Contact Start -->
    <contact></contact>
    <!--end section-->
    <!-- Contact End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->

    <!-- Back to top -->
    <a id="back-to-top" href="#" class="back-to-top rounded text-center">
      <i class="mdi mdi-chevron-up d-block"></i>
    </a>
    <!-- Back to top -->
  </div>
</template>

<script>
import $ from "jquery";
import AOS from "aos";
import feather from "feather-icons";

import { PORTFOLIO_DATA } from "./assets/data";

import Portfolio from "@/views/Home/components/Portfolio";
import WorkExperience from "@/views/Home/components/WorkExperience";
import Contact from "@/views/Home/components/Contact";
import Intro from "@/views/Home/components/Intro";
import Skill from "@/views/Home/components/Skill";
import Service from "@/views/Home/components/Service";

import AppFooter from "@/layouts/Footer";
import Navbar from "@/layouts/Navbar.vue";

import generalMixin from "@/mixins/general.mixin";

export default {
  name: "App",
  components: {
    Contact,
    Intro,
    Skill,
    WorkExperience,
    Portfolio,
    AppFooter,
    Navbar,
    AppService: Service,
  },
  mixins: [generalMixin],
  data() {
    return {
      portfolioDetails: PORTFOLIO_DATA,
    };
  },
  provide() {
    return {
      portfolioDetails: this.portfolioDetails,
    };
  },
  computed: {},
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);

    $(window).on("load", function () {
      $("#status").fadeOut();
      $("#preloader").delay(200).fadeOut("slow");
      $("body").delay(200).css({
        overflow: "visible",
      });
    });

    // Animate on scroll
    AOS.init({
      easing: "ease-in-out-sine",
      duration: 1000,
      once: true,
    });
  },
  mounted() {
    $(".navbar-nav a, .mouse-down").on("click", function (event) {
      var $anchor = $(this);
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr("href")).offset().top - 0,
          },
          1500,
          "easeInOutExpo"
        );
      event.preventDefault();
    });

    // BACK TO TOP
    $(".back-to-top").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, 3000);
      return false;
    });

    // Scrollspy
    setTimeout(() => {}, 4000);

    setTimeout(() => {
      $(".navbar-nav").scrollspy({ offset: 70 });
      // $(".navbar-nav").scrollspy("refresh");
    }, 1000);

    //Feather icon
    feather.replace();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scroll = $(window).scrollTop();
      let pageYOffset = window.pageYOffset;
      let projectOffset = this.$refs.portfolioSec.$el.offsetTop;

      if (
        pageYOffset >= projectOffset - 500 &&
        pageYOffset <= projectOffset + 1000
      ) {
        this.filterPortfolio();
      }

      if (scroll >= 50) {
        $(".sticky").addClass("nav-sticky");
      } else {
        $(".sticky").removeClass("nav-sticky");
      }

      // BACK TO TOP
      if (scroll > 100) {
        $(".back-to-top").fadeIn();
      } else {
        $(".back-to-top").fadeOut();
      }
    },
    filterPortfolio() {
      if (this.$refs.portfolioSec.selectedProjectCategory) {
        this.$refs.portfolioSec.onFilterProject(
          this.$refs.portfolioSec.selectedProjectCategory
        );
      } else {
        this.$refs.portfolioSec.onFilterProject();
      }
    },
  },
};
</script>
